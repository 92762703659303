body {
    background-color: rgb(255, 255, 255);
}

html, body, header, footer, section, nav, aside, article, figure, figcaption, td, div, p, ul, ol, li, dl, dt, dd, select, input, textarea, label, button, hr {
    color: rgb(119, 119, 119);
    font: 400 16px/28px 'Josefin Sans', Helvetica, Arial, Tahoma, sans-serif;
    margin: 0;
    padding: 0;
    list-style: none;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3 {
    color: rgb(128, 128, 128);
    font-family: 'Josefin Sans', Helvetica, Arial, Tahoma, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

strong, b {
    font-weight: 600;
}

header {
    min-height: 180px;
    padding-top: 20px;
}

header a::before {
    border: 1px solid rgb(119, 119, 119);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: rgb(119, 119, 119);
    content: '\003c';
    font-size: 30px;
    display: block;
    height: 30px;
    padding: 10px 15px;
    position: absolute;
    text-indent: 0;
    width: 20px;
}

header a {
    float: left;
    color: rgb(119, 119, 119);
    display: block;
    text-decoration: none;
    top: 20px;
    margin-left: 20px;
}

header img {
    display: block;
    margin: 0 auto;
    max-width: 500px;
    max-height: 180px;
}

#login h1 {
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#login form {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    width: 244px;
}

#login form input[type=text],
#login form input[type=password] {
    background-color: rgb(213, 223, 233);
    background-image: url(../images/icons-login.svg);
    background-size: 50px auto;
    background-repeat: no-repeat;
    background-position: 10px 9px;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: rgb(170, 178, 186);
    font-size: 16px;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 10px 0 50px;
    width: 184px;
}

#login form input[type=password] {
    background-position: 10px -43px;
}

#login form input[type=submit],
#login form button {
    border: none;
    background-color: rgb(200, 200, 200);
    color: rgb(255, 255, 255);
    font-size: 16px;
    height: 40px;
    width: 244px;
    -webkit-appearance: none;
}

#login .error_list li {
    background-color: rgb(204, 0, 0);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
}

footer, p {
    color: rgb(133, 135, 135);
    text-align: center;
    margin-top: 20px;
    padding-top: 40px;
}

p {
    text-align: center;
    margin-top: 20px;
}

footer a, p a {
    color: rgb(133, 135, 135);
}

/* Article */
article {
    text-align: justify;
    max-width: 800px;
    margin: 0 auto;
}

article p {
    text-align: justify;
    color: black;
}

article h2,
article h3 {
    padding-top: 20px;
}